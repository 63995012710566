<template>
  <card
    :content="content"
    :schema="getSchemaPacking"
    :form-data="getDataPacking"
    :editable="getEditablePacking"
    @update-form="updatePacking"
    @submitAction="addPacking"
  />
</template>

<script>
import Card from "../components/Card";
import confPacking from "../conf/confPacking";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Packing",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confPacking.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("packing/loadPacking");
  },
  computed: {
    ...mapGetters("packing", [
      "getDataPacking",
      "getSchemaPacking",
      "getEditablePacking"
    ])
  },
  methods: {
    ...mapActions("packing", ["updatePacking", "addPacking", "loadPacking"])
  }
};
</script>

<style scoped></style>
